<template>
  <div class="table-box">
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="{
        current: current,
        pageSize: pageSize,
        pageSizeOptions: ['11','20','30','40'],
        total: total,
        showSizeChanger: true,
        showTotal: (total) => `共 ${total} 条`,
        onChange: onChange,
        onShowSizeChange: onShowSizeChange,
      }"
      row-key="engagementCode"
      :scroll="{ y: tableHeight }"
    >
      <template #action="{ record }">
        <ActionBtn v-bind="record" :btnList="btnList1" @icon-info="toAssignmentDetails(record)" @FormOutlined="handleModifyAss(record)" @DownloadOutlined="downloadAssignmentFile(record)" />
      </template>
      <template #pendingAction="{ record }">
        <ActionBtn v-bind="record" :btnList="btnList2" @icon-info="toPendingDetails(record)" @FormOutlined="handleModifyAss(record)" />
      </template>
      <template #engagementCode="{ text }">
        <span class="codeColor">{{ text }}</span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { useStore } from "vuex";

import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import { useTableHeight } from "@/utils/common.js";
import { computed } from "@vue/runtime-core";
import ActionBtn from '@/components/ActionBtn'

const btnList1 =[
  {
    name: '详情信息',
    icon: 'icon-info',
    auth: ['system:assignment:assignmentDetails']
  },
  {
    name: '修改',
    icon: 'FormOutlined',
    auth: ['system:assignment:modifyCode:rm']
  },
  {
    name: '下载',
    icon: 'DownloadOutlined',
    auth: ['system:assignment:exportAssignmentFile']
  },
]
const btnList2 =[
  {
    name: '详情信息',
    icon: 'icon-info',
    auth: ['system:assignment:pendingDetails']
  },
  {
    name: '修改',
    icon: 'FormOutlined',
    auth: ['system:assignment:modifyCode:rm']
  },
]
export default defineComponent({
  components: {ActionBtn},
  props: {
    data: Array,
    condition: Object,
    total: Number,
    activeTab: String,
    pageIndex: Number,
  },
  emits: ["get-assignmentdata-list", "get-pending-list", "row-export-click", "open-modify-ass"],
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const pageSize = ref(11);
    const current = ref(props.pageIndex || 1);
    const tableHeight = ref(0);
    onMounted(() => {
      console.log(props.data);
    });
    watch(() => props.pageIndex,(val) => {
      current.value = val;
    });
    const onChange = (page, size) => {
      current.value = page;
      pageSize.value = size;
      if(props.activeTab === '1') {
        emit("get-assignmentdata-list", current.value, pageSize.value);
      }else {
        emit("get-pending-list", current.value, pageSize.value);
      }

    };
    const onShowSizeChange = (_, size) => {
      current.value = 1;
      pageSize.value = size;
      if(props.activeTab === '1') {
        emit("get-assignmentdata-list", current.value, pageSize.value);
      }else {
        emit("get-pending-list", current.value, pageSize.value);
      }
    };
    const toAssignmentDetails = (record) => {
      // 进行中 ---> hardbook ---> 已完成 ---> 失效
      let status = "2";
      if (!record.actualInProgress) {
        if (record.actualInvalid) {
          status = "4";
        }
        if (record.actualCompleted) {
          status = "3";
        }
        if (record.actualHardBook) {
          status = "1";
        }
      }
      const condition = {
        ...props.condition,
        ...record,
        isPending: false,
      };
      store.commit("SET_ASSIGNMENT_CONDITION", condition);
      router.push({
        path: "/assignmentDetails",
        query: {
          status,
        },
      });
    };
    const downloadAssignmentFile = (record) => {
      emit("row-export-click", record)
    }
    const toPendingDetails = (record) => {
      // 进行中 ---> hardBook
      let status = "2";
      if (!record.actualInProgress) {
        status = "1";
      }
      const condition = {
        ...props.condition,
        ...record,
        isPending: true,
      };
      store.commit("SET_ASSIGNMENT_CONDITION", condition);
      router.push({
        path: "/assignmentDetails",
        query: {
          status,
        },
      });
    };

    watch(
      () => props.activeTab,
      () => {
        current.value = 1;
        pageSize.value = 11;
      }
    );

    const columns = computed(() => {
      if (props.activeTab === "1") {
        return [
          {
            title: "Engagement Code",
            dataIndex: "engagementCode",
            key: "engagementCode",
            slots: { customRender: "engagementCode" },
            width: 150,
            ellipsis: true,
          },
          {
            title: "Engagement Description",
            dataIndex: "engagementDescription",
            key: "clientName",
            width: 250,
            ellipsis: true,
          },
          {
            title: "未上项目人数",
            dataIndex: "hardBook",
            key: "hardBook",
            width: 160,
            ellipsis: true,
          },
          {
            title: "进行中人数",
            dataIndex: "inProgress",
            key: "inProgress",
            width: 160,
            ellipsis: true,
          },
          {
            title: "已完成人数",
            dataIndex: "completed",
            key: "completed",
            width: 160,
            ellipsis: true,
          },
          {
            title: "已失效人数",
            dataIndex: "invalid",
            key: "invalid",
            width: 160,
            ellipsis: true,
          },
          {
            title: "操作",
            dataIndex: "action",
            key: "action",
            slots: { customRender: "action" },
            width: 140,
            fixed: "right",
          },
        ];
      } else {
        return [
          {
            title: "Engagement Code",
            dataIndex: "engagementCode",
            key: "engagementCode",
            slots: { customRender: "engagementCode" },
            ellipsis: true,
          },
          {
            title: "Engagement Description",
            dataIndex: "engagementDescription",
            key: "clientName",
            ellipsis: true,
          },
          {
            title: "未上项目人数",
            dataIndex: "hardBook",
            key: "hardBook",
            ellipsis: true,
          },
          {
            title: "进行中人数",
            dataIndex: "inProgress",
            key: "inProgress",
            ellipsis: true,
          },
          {
            title: "操作",
            dataIndex: "pendingAction",
            key: "pendingAction",
            slots: { customRender: "pendingAction" },
            width: 100,
            fixed: "right",
          },
        ];
      }
    });
    const isDisabledModify = (record) => {
      return record.hardBook == 0 && record.inProgress == 0
    }
    const handleModifyAss = (record) => {
      // 进行中 ---> hardbook ---> 已完成 ---> 失效
      let status = "2";
      if (!record.actualInProgress) {
        if (record.actualInvalid) {
          status = "4";
        }
        if (record.actualCompleted) {
          status = "3";
        }
        if (record.actualHardBook) {
          status = "1";
        }
      }
      const condition = {
        ...props.condition,
        ...record,
        isPending: false,
      };

      emit("open-modify-ass", record,{status,condition})
    }

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });
    return {
      columns,
      pageSize,
      current,
      tableHeight,
      onChange,
      onShowSizeChange,
      toAssignmentDetails,
      toPendingDetails,
      downloadAssignmentFile,
      handleModifyAss,
      isDisabledModify,
      btnList1,
      btnList2
    };
  },
});
</script>
<style lang="less" scoped>
@import "~@/style/theme.less";
.table-box {
  background-color: #fff;
}

:deep(.ant-table-content) {
  margin: 10px;
}

.cBtn {
  color: #1890ff;
  border-color: #1890ff;
}

.codeColor {
  color: #1890ff;
}

.pagination {
  position: absolute;
  bottom: 15px;
  right: 40px;
  height: 48px;
}

</style>
