<template>
  <section class="app-container">
    <div class="top-filter">
        <div class="left">
          <a-tabs
              v-model:activeKey="activeTab"
              defaultActiveKey="1"
              class="left"
              v-auth="['system:assignmentDetails:batchTab']"
              @change="resetForm"
          >
            <a-tab-pane key="1" tab="全部"></a-tab-pane>
            <a-tab-pane key="2" tab="待审批"></a-tab-pane>
          </a-tabs>
        </div>
        <div class="right"></div>
    </div>
    <div class="container">
      <div class="">
        <FilterLayout @doReset="resetForm"
                      @doSearch="getAssaginmentDataList"
                      ref="filterLayoutRef">
          <template #customform>
            <a-form
                ref="formRef"
                class="form rms-form"
                layout="vertical"
                :model="formState"
            >
              <template v-for="(item,index) in queryList" :key="index">
                <a-form-item :label="item.code" name="name" class="rms-form-item">
                  <template v-if="item.type==='2'">
                    <a-select
                        v-model:value="formState[item.inputDataType]"
                        :options="getOptions(item.inputDataType)"
                        :placeholder="getPlaceHoder(item.inputDataType)"
                        allowClear
                        show-search
                    >
                    </a-select>
                  </template>
                  <template v-if="item.type==='3'">
                    <a-input
                        v-model:value="formState[item.inputDataType]"
                        :placeholder="getPlaceHoder(item.inputDataType)"
                        allowClear
                        autocomplete="off"
                    />
                  </template>
                  <template v-if="item.type==='5'">
                    <SelectWithAll
                        v-model:value="formState[item.inputDataType]"
                        :options="getOptions(item.inputDataType)"
                        :placeholder="getPlaceHoder(item.inputDataType)"
                        mode="multiple"
                        showArrow
                        allowClear
                        :maxTagCount="1"
                        @change="getSelectChange(item.inputDataType)"
                    >
                    </SelectWithAll>
                  </template>
                </a-form-item>
              </template>
            </a-form>
          </template>
        </FilterLayout>
      </div>
        <assignment-list
          :data="dataSource"
          :condition="formState"
          :total="total"
          :pageIndex="pageIndex"
          :active-tab="activeTab"
          @get-assignmentdata-list="getDataList"
          @get-pending-list="getPendingList"
          @row-export-click="downloadAssignmentFile"
          @open-modify-ass="openModifyAss"
        />
      <AssignmentsModifying
          title="修改"
          v-model:visible="modifyingVisible"
          :initialDataList="initialDataList"
          :engageCodeOpts="engageCodeOpts"
          :oldEngageCode="oldEngageCode"
          :descriptionOps="descriptionOps"
          @get-detail-by-code="getTheDetailByCode"
          @handle-save-assignment="handleCheckAssignment"
          @reset-data-list="resetDataList"
          :titleEngagementDescription = "titleEngagementDescription"
      />
    </div>
  </section>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
</template>

<script>
import {
  computed,
  defineComponent,
  onActivated,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import AssignmentList from "./component/AssignmentList.vue";
import AssignmentsModifying from "./component/AssignmentsModifying";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useStore } from "vuex";
import {dictionaryOpt, downloadFile} from "@/utils/common";
import {cloneDeep, debounce} from "lodash";
import {message} from "ant-design-vue";
import {onBeforeRouteLeave, useRoute} from "vue-router";
import ModalBox from '@/components/ModalBox'
import moment from "moment";
export default defineComponent({
  name: "Assignment",
  components: { AssignmentList, AssignmentsModifying, ModalBox },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const route = useRoute();
    const dataSource = ref([]);
    const total = ref();
    const pageIndex = ref(1);
    let current = 1;
    let pageSize = 11;
    let currentState = {};
    const queryList = ref([])
    const checkList = ref([])
    const queryFormList = (data) => {
      $api.queryFilterSort({
        firstMenu: 'resourceManagement',
        secondMenu: 'assignmentManagement',
        isQueryDefault: data.isQueryDefault
      }).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getOptions = (data)=> {
      switch (data) {
        case 'orgThreeTwoList':
          return orgThreeTwoOps.value
        case 'orgFourOneOneList':
          return orgFourOneOneOps.value
        case 'assignmentType':
          return assignmentTypeOps.value
        default :
          ''  // 如果没
      }
    }
    const getPlaceHoder = (data)=> {
      switch (data) {
        case 'condition':
          return 'Name/GGID'
        case 'engagementDescription':
          return '项目名称/项目code'
        default :
          return '请选择'  // 如果没
      }
    }
    const getSelectChange = debounce((data)=>{
      switch (data) {
        case 'orgThreeTwoList':
          return orgThreeTwoChange()
        default :
          ''  // 如果没
      }
    },500)
    const updateParams =() => {
      currentState = cloneDeep(formState)
    }
    const getDataList = (currentParam, pageSizeParam) => {
      current = currentParam ? currentParam : 1;
      pageSize = pageSizeParam ? pageSizeParam : pageSize;
      const data = Object.assign(
        {
          condition: currentState.condition,
          engagementDescription: currentState.engagementDescription,
          assignmentType: currentState.assignmentType,
          pageIndex: current,
          pageSize: pageSize,
        },
        {
          orgThreeTwoList: currentState.orgThreeTwoList,
          orgFourOneOneList: currentState.orgFourOneOneList,
        }
      );
      $api.getAssignmentSearchList(data).then((res) => {
        dataSource.value = res.data.resource;
        total.value = res.data.total;
        pageIndex.value = res.data.pageIndex + 1;
      });
    };
    const downloadAssignmentFile = (record) => {
      const data = {
          condition: formState.condition,
          engagementDescription: formState.engagementDescription,
          assignmentType: formState.assignmentType,
          orgThreeTwoList: formState.orgThreeTwoList,
          orgFourOneOneList: formState.orgFourOneOneList,
        engagementCode: record.engagementCode
      };
      $api.downloadAssignmentFile(data).then(res => {
        downloadFile(res.file, res.filename);
      })
    }

    const formRef = ref();
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);

    const resetForm = () => {
      Object.assign(formState, {
        orgThreeTwoList: [],
        orgFourOneOneList: [],
        engagementDescription: "",
        condition: "",
        assignmentType: undefined,
      });
      // activeTab.value = "1";
      current = 1;
      pageSize = 11;
      updateParams();
      getAssaginmentDataList();
      isPM.value || isPMAuthorization.value ? getPmOrgItems() : getOrgList()
    };

    const formState = reactive({
      orgThreeTwoList: [],
      orgFourOneOneList: [],
      engagementDescription: "",
      condition: "",
      assignmentType: undefined,
    });
    const getOrgList = () => {
      //查询org3.2/org4.2
      $api.getOrgThree_FourList().then((res) => {
        const { orgThreeTwo, orgFourOneOne } = res.data;
        orgThreeTwoOps.value = dictionaryOpt(orgThreeTwo);
        orgFourOneOneOps.value = dictionaryOpt(orgFourOneOne);
      });
    };
    const getPmOrgItems = () => {
      $api.getItemsPmOrg().then((res) => {
        const { orgThreeTwo, orgFourOneOne } = res.data;
        orgThreeTwoOps.value = dictionaryOpt(orgThreeTwo);
        orgFourOneOneOps.value = dictionaryOpt(orgFourOneOne);
      });
    }
    const assignmentTypeOps = ref([]);
    const getAssignmentTypes = () => {
      $api
        .getDictionaryList({
          params: {
            dictType: "assignmentType",
          },
        })
        .then((res) => {
          assignmentTypeOps.value = (res.data?.assignmentType || []).map(({ dictCode, dictValue}) => ({
            label: dictValue,
            value: dictCode,
          }));
        });
    };
    const isRM = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("2") !== -1
    })

    const isRMLeader = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("11") !== -1
    })
    const isPM = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("3") !== -1
    })
    const isPMAuthorization =computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("14") !== -1
    })
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        formState.positionId = undefined
        formState.lineManager = ''
        formState.reviewTime = []
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }
    onMounted(() => {
      if(isRM.value || isRMLeader.value) {
        getOrgList();
      }
      if(isPM.value || isPMAuthorization.value) {
        getPmOrgItems();
      }
      getAssignmentTypes();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });
    watch(
        () => route.query.id,
        (val) => {
          if(val) {
            if(route.path === '/assignment') {
              activeTab.value = '1'
              formState.engagementDescription = route.query.id
              getAssaginmentDataList()
            }
          }
        },
    )
    onActivated(() => {
      if(route.query.id) {
        activeTab.value = '1'
        formState.engagementDescription = route.query.id
        getAssaginmentDataList()
      } else {
        getAssaginmentDataList();
      }
    });
    onBeforeRouteLeave(() => {
      if (route.query.id) {
        formState.engagementDescription = ''
      }
    });

    const getPendingList = () => {
      current = current ? current : 1;
      pageSize = pageSize ? pageSize : 11;
      const data = {
        engagementDescription: currentState.engagementDescription,
        assignmentType: currentState.assignmentType,
        condition: currentState.condition,
        pageIndex: current,
        pageSize: pageSize,
        orgThreeTwoList: currentState.orgThreeTwo,
        orgFourOneOneList: currentState.orgFourOneOne,
      };
      $api.getAssignmentPendingList(data).then((res) => {
        dataSource.value = res.data.resource;
        total.value = res.data.total;
      });
    };

    const orgThreeTwoChange = debounce(() => {
      formState.orgFourOneOneList = [];
      if(isPM.value || isPMAuthorization.value) {
        if (formState.orgThreeTwoList.length) {
          $api.getOrgFourOneOne({ condition: formState.orgThreeTwoList.join() }).then((res) => {
            orgFourOneOneOps.value = dictionaryOpt(res.data.orgFourOneOne);
          });
        } else {
          getPmOrgItems();
        }
      } else {
        if (formState.orgThreeTwoList.length) {
          $api.getOrgFour({ condition: formState.orgThreeTwoList.join() }).then((res) => {
            orgFourOneOneOps.value = dictionaryOpt(res.data.orgFourOneOne);
          });
        } else {
          getOrgList();
        }
      }
      //getAssaginmentDataList();
    },500)

    const getAssaginmentDataList = () => {
      updateParams()
      // 重置页码
      current = 1;
      if (activeTab.value === "1") {
        getDataList(current, pageSize);
      } else {
        getPendingList(current, pageSize);
      }
    };

    const isTodoStatus = computed(() => store.state.app.isTodoStatus);

    const activeTab = ref("1");
    const show = ref(true);

    const modifyingVisible = ref(false)
    const initialDataList = ref([])
    const engageCodeOpts = ref([])
    const oldEngageCode = ref("")

    const openModifyAss = async (record,param) => {
      oldEngageCode.value = record.engagementCode
      initialDataList.value = [{
        id: "",
        engagementCode: "",
        engagementDescription: "",
        account: "",
        projectStartDate: "",
        projectCloseDate: "",
        engagementManagerNumber: "",
        engagementManagerName: "",
      }]
      const params = {
        engagementCode: oldEngageCode.value,
        pageIndex:1,
        pageSize:100,
        type:param.condition.type ? param.condition.type : "project",
        status:8+""
      }
       $api.getAssignmentDetails(params).then((res) => {
        console.log("res!!",res)
        initialDataList.value = res.data.detailPage.resource.map((item, index) => {
        return {
          assignmentId: item.id,
          index: index+1,
          candidateName: item.candidateName,
          hoursPerDay: item.hoursPerDay,
          estimateReleaseDate: item.estimateReleaseDate,
          onBoardDate: item.onBoardDate,
        }
      })
      })
      await getEngageCodeOpts();
      modifyingVisible.value = true;
    }
    const getAssDetail = async (engagementCode) => {
      let flag = false
      const params = {
        engagementCode,
      }
      await $api.getCodeDataByEngagementCode(params).then((res) => {
        if(res.data.length > 0) {
          initialDataList.value = [...res.data]
        }else {
          message.error("暂无数据");
          flag = true
        }
      })
      return flag
    }
    const titleEngagementDescription = ref("");
    const getTheDetailByCode = (engagementCode, key) => {
      const params = {
        engagementCode,
      }
      $api.getCodeDataByEngagementCode(params).then((res) => {
        if(res.data.length > 0) {
          if(!key&&key!=0){
            initialDataList.value = initialDataList.value.map((item) => {
              return item = Object.assign({},item, res.data[0])
            })
            titleEngagementDescription.value = initialDataList.value[0].engagementDescription
            console.log(initialDataList.value,'initialDataList进if')
          }else{
            console.log(initialDataList.value,"assign前的")
          initialDataList.value[key] = Object.assign({},initialDataList.value[key], res.data[0])
          console.log(initialDataList.value,'initialDataList没进if')
          }
        }else {
          message.error("暂无数据");
          
        }
      })
    }
    const descriptionOps = ref([]);
    const getEngageCodeOpts = async () => {
      const { data } = await $api.getCodeItems()
      engageCodeOpts.value = data.map((item, index) => {
        return {
          ...item,
          key: index,
          label: item.codeAndDescription,
          value: item.codeAndDescription,
        }
      })
      descriptionOps.value = data.map((item, index) => {
        return {
          ...item,
          key: index,
          label: item.engagementDescription,
          value: item.engagementDescription,
        }
      })
    }

    const resetDataList = (engagementCode, key) => {
      if(initialDataList.value.length > 0) {
        if(!key&&key!=0){
          titleEngagementDescription.value = ""
          initialDataList.value = initialDataList.value.map((ele) => {
            return Object.assign({}, ele, {
              engagementCode:"",
              engagementDescription:"",
              account : "",
              projectStartDate : "",
              projectCloseDate : "",
              engagementManagerName : "",
              engagementManagerNumber : "",
            })
          })
        }else{
        initialDataList.value[key].engagementDescription = ""
        initialDataList.value[key].account = ""
        initialDataList.value[key].projectStartDate = ""
        initialDataList.value[key].projectCloseDate = ""
        initialDataList.value[key].engagementManagerName = ""
        initialDataList.value[key].engagementManagerNumber = ""
        }
      }
    }

    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const modalData = ref()
    const handleOk = () => {
      handleSaveAssignment(modalData.value)
    }
    const flag = ref('')
    const isDateConflict = (data) => {
      let flag = false
      // Project Start Date≤On Board Date≤Estimate Release Date≤Project Closed Date
      if(moment(data.projectStartDate) > moment(data.onBoardDate)) {
        flag = true
        return flag
      }
      if(data.projectCloseDate && (moment(data.estimateReleaseDate)) > moment(data.projectCloseDate)) {
        flag = true
        return flag
      }
      return flag
    }
    const handleCheckAssignment = (dataList) => {
      const failedIndexList = []
      let isPass = true
      dataList.forEach((item, index) => {
        if(isDateConflict(item)) {
          isPass = false
          failedIndexList.push(index + 1)
        }
      })
      // 都通过校验 -调保存方法
      if(isPass) {
        handleSaveAssignment(dataList)
      }else {// 不通过 -弹窗提示
        content.value = `第${failedIndexList.join('、')}行，调令开始/结束时间与Code有效期冲突,请确定是否修改?`
        showIconCom.value = 'ExclamationCircleOutlined'
        flag.value = 2
        modalData.value = dataList
        modelValue.value = true
        
      }
    }
    const handleSaveAssignment = async (paramList) => {
      const params = {
        codeDataPojoList: paramList
      }
      const { code } = await $api.bachSaveAssignments(params)
      if(code === 200) {
        message.success("保存成功！");
        modifyingVisible.value = false;
        getDataList()
      }
    }
    return {
      queryFormList,
      queryList,
      checkList,
      getOptions,
      getPlaceHoder,
      getSelectChange,
      isShowMoreList,
      showMoreForm,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      show,
      store,
      dataSource,
      getDataList,
      formRef,
      orgThreeTwoOps,
      orgFourOneOneOps,
      resetForm,
      formState,
      orgThreeTwoChange,
      total,
      getAssaginmentDataList,
      isTodoStatus,
      activeTab,
      getPendingList,
      pageIndex,
      assignmentTypeOps,
      downloadAssignmentFile,
      isRMLeader,
      isRM,
      updateParams,
      openModifyAss,
      modifyingVisible,
      initialDataList,
      engageCodeOpts,
      getAssDetail,
      getTheDetailByCode,
      handleCheckAssignment,
      handleSaveAssignment,
      oldEngageCode,
      isPM,
      getPmOrgItems,
      resetDataList,
      modelValue,
      content,
      showIconCom,
      handleOk,
      isPMAuthorization,
      descriptionOps
    };
  },
});
</script>

<style lang="less" scoped>
@import "~@/style/newTitleTab.less";
</style>
